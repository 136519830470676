<template>
  <Container class="d-print-block">
    <div class="d-flex justify-space-between pr-3 pl-3 mb-1">
      <ClinicDetail flexDirection="column" style="width: 50%">
        <span class="normal__case w-100">
          {{ clinic.clinicName }}
        </span>
        <span class="normal__case w-100">
          {{ clinic.address }}
        </span>
        <span class="normal__case w-100">
          {{ clinic.hotline }}
        </span>
      </ClinicDetail>
      <ImageLogoBox>
        <img
          class="imageLogoBox_Image"
          src="../../../assets/CT_logo.png"
          alt="Logo Cao thang"
        />
      </ImageLogoBox>
    </div>
    <TitlePrescription>
      <span v-html="isVnLanguage ? 'Đơn kính' : 'Glass Prescription'"></span>
    </TitlePrescription>
    <PatientInfo class="mt-1">
      <div class="d-flex justify-space-between w-100 pl-3 pr-3">
        <div class="d-flex flex-column">
          <label class="patientInfo__item">
            {{ isVnLanguage ? "Họ tên: " : "Name: " }}
            <span class="upper__case">{{ personalInfo.fullName }}</span>
          </label>
          <label class="patientInfo__item">
            {{ isVnLanguage ? "Giới tính: " : "Gender: " }}
            <span class="bold__case">
              {{ personalInfo.genderTypeName }}
            </span>
          </label>
          <label class="patientInfo__item">
            {{ isVnLanguage ? "Địa chỉ: " : "Address: " }}
            <span class="bold__case">{{ personalInfo.address }}</span>
          </label>
        </div>

        <div class="d-flex flex-column">
          <label class="patientInfo__item">
            {{ isVnLanguage ? "Mã hồ sơ: " : "Patient ID: " }}
            <span class="bold__case">{{ personalInfo.patientID }}</span>
          </label>
          <span class="patientInfo__item">
            {{ isVnLanguage ? "Ngày sinh: " : "BirthDay: " }}
            {{ formatDate(personalInfo.birthDate) }}
            <b class="bold__case">
              ({{ isVnLanguage ? "Tuổi: " : "Ages: " }}
              {{
                new Date().getFullYear() -
                new Date(personalInfo.birthDate).getFullYear()
              }})
            </b>
          </span>
        </div>
      </div>
    </PatientInfo>

    <DetailPrescription class="mt-2">
      <TableContent>
        <thead>
          <tr>
            <th colspan="2"></th>
            <th colspan="3">Mắt phải</th>
            <th colspan="3">Mắt trái</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="2" class="title__prescription">Loại tật khúc xạ</td>
            <td colspan="3" class="content__prescription">
              <span>{{ od.subjective.refractiveError }}</span>
            </td>
            <td colspan="3" class="content__prescription">
              <span>{{ os.subjective.refractiveError }}</span>
            </td>
          </tr>
          <!-- Current glass Condition is not IOL-->
          <template v-if="this.ou.currentGlassCondition.iol == false">
            <!-- Check current glass -->
            <template
              v-if="
                checkCurrentGlass(this.od.currentGlass) ||
                checkCurrentGlass(this.os.currentGlass)
              "
            >
              <tr>
                <td :rowspan="this.totalRowSpanCG" class="title__prescription">
                  Độ kính đang sử dụng
                </td>

                <td class="text-left pa-2 title__prescription">
                  {{ nameItemCG(firstCGElement.name) }}
                </td>
                <td colspan="3" class="content__prescription">
                  <div class="d-flex flex-column">
                    <span class="d-block w-100">{{
                      firstCGElement.od.rx
                    }}</span>
                    <span class="d-block w-100">{{
                      firstCGElement.od.va ? `(${firstCGElement.od.va})` : ""
                    }}</span>
                  </div>
                </td>
                <td colspan="3" class="content__prescription">
                  <span class="d-block w-100">{{ firstCGElement.os.rx }}</span>
                  <span class="d-block w-100">{{
                    firstCGElement.os.va ? `(${firstCGElement.os.va})` : ""
                  }}</span>
                </td>
              </tr>
              <!--  print list CG ITEM after removing first element of CG -->
              <tr v-for="(itemCG, index) in listCGElement" :key="index">
                <td class="text-left pa-2 title__prescription">
                  {{ nameItemCG(itemCG.name) }}
                </td>
                <td colspan="3" class="content__prescription">
                  <span class="d-block w-100">{{ itemCG.od.rx }}</span>
                  <span class="d-block w-100">{{
                    itemCG.od.va ? `(${itemCG.od.va})` : ""
                  }}</span>
                </td>
                <td colspan="3" class="content__prescription">
                  <span class="d-block w-100">{{ itemCG.os.rx }}</span>
                  <span class="d-block w-100">{{
                    itemCG.os.va ? `(${itemCG.os.va})` : ""
                  }}</span>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="2" class="title__prescription">
                  Độ kính đang sử dụng
                </td>
                <td colspan="3" class="content__prescription"></td>
                <td colspan="3" class="content__prescription"></td>
              </tr>
            </template>
          </template>

          <!-- Current glass condition is IOL -->
          <template v-else>
            <tr>
              <td colspan="2" class="title__prescription">
                Độ kính đang sử dụng
              </td>
              <td colspan="3" class="content__prescription">
                <span>{{ od.currentGlass.iol ? "IOL" : "" }}</span>
              </td>
              <td colspan="3" class="content__prescription">
                <span>{{ os.currentGlass.iol ? "IOL" : "" }}</span>
              </td>
            </tr>
          </template>

          <tr>
            <td rowspan="2" class="title__prescription">Thị lực xa</td>
            <td class="text-left pa-2 title__prescription">Không kính</td>
            <td colspan="3" class="content__prescription">
              <span>{{ od.visualAcuity.sc }}</span>
            </td>
            <td colspan="3" class="content__prescription">
              <span>{{ os.visualAcuity.sc }}</span>
            </td>
          </tr>
          <tr>
            <td class="text-left pa-2 title__prescription">Có kính</td>
            <td colspan="3" class="content__prescription">
              <span>{{ od.subjective.va }}</span>
            </td>
            <td colspan="3" class="content__prescription">
              <span>{{ os.subjective.va }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="title__prescription">Thị lực 2 mắt</td>
            <td colspan="6" class="content__prescription">
              <span>{{ ou.subjective.bcva }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="title__prescription">Kính điều chỉnh</td>
            <td colspan="3" class="content__prescription">
              <span>{{ od.subjective.rx }}</span>
            </td>
            <td colspan="3" class="content__prescription">
              <span>{{ os.subjective.rx }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="title__prescription">Add , Thị lực gần</td>
            <td colspan="3" class="content__prescription">
              <span>{{ od.subjective.addToNVA }}</span>
            </td>
            <td colspan="3" class="content__prescription">
              <span>{{ os.subjective.addToNVA }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="title__prescription">
              Skiascopy
              <b>{{ ou.objective.dlc ? `(${ou.objective.dlc})` : "" }}</b>
            </td>
            <td colspan="3" class="content__prescription">
              <span>{{ od.objective.rx }}</span>
            </td>
            <td colspan="3" class="content__prescription">
              <span>{{ os.objective.rx }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="title__prescription">Khoảng cách đồng tử</td>
            <td colspan="6" class="content__prescription font-weight-bold">
              <span>{{ ou.pd ? `${ou.pd} mm` : "" }}</span>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="title__prescription">
              Kính đề nghị <b>{{ printTypeNewGlass }}</b>
            </td>
            <td colspan="3" class="content__prescription font-weight-bold">
              <span class="d-block w-100">
                {{
                  od.newGlass.showRxResult
                    ? od.newGlass.rx
                    : printStatusGlass(od.newGlass.statusGlass)
                }}
              </span>
              <span class="d-block w-100">
                {{
                  od.newGlass.showRxResult && od.newGlass.add
                    ? `(${od.newGlass.add})`
                    : ""
                }}
              </span>
            </td>
            <td colspan="3" class="content__prescription font-weight-bold">
              <span class="d-block w-100">
                {{
                  os.newGlass.showRxResult
                    ? os.newGlass.rx
                    : printStatusGlass(os.newGlass.statusGlass)
                }}
              </span>
              <span class="d-block w-100">
                {{
                  os.newGlass.showRxResult && os.newGlass.add
                    ? `(${os.newGlass.add})`
                    : ""
                }}
              </span>
            </td>
          </tr>
        </tbody>
      </TableContent>
      <Footer>
        <slot name="footer">
          <v-row justify="space-between">
            <v-col cols="6">
              <div class="d-block w-100">
                <span class="note__title">Dặn dò:</span>
                <div class="d-flex w-100 flex-column">
                  <span
                    class="text__note"
                    v-for="(item, index) in displayNote"
                    :key="index"
                    >{{ item }}</span
                  >
                </div>
              </div>
            </v-col>
            <v-col cols="4">
              <div class="footer__staffHandler">
                <span class="footer__staffHandler__date"
                  >Ngày: <b class="bold__case">{{ today }}</b></span
                >
                <span class="footer__staffHandler__role">Kỹ thuật viên</span>
                <div class="footer__staffHandler__signal"></div>
                <span class="footer__staffHandler__name">{{ userName }}</span>
              </div>
            </v-col>
          </v-row>
        </slot>
      </Footer>
    </DetailPrescription>
  </Container>
</template>

<script>
import { awaitAll } from "@/plugins/helper";

import moment from "moment";
import {
  Container,
  TableContent,
  ClinicDetail,
  ImageLogoBox,
  PatientInfo,
  TitlePrescription,
  Footer,
  DetailPrescription,
} from "../Print/css/style";

import OptometryConclusionService from "@/services/optometryConclusion";
import PatientService from "@/services/patient";
import BrandService from "@/services/brand";
import i18n from "@/plugins/i18n";

// update
export default {
  name: "GlassPrescription",
  props: {
    patientID: {
      type: Number,
      default: null,
    },
    hideRawData: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    Container,
    TableContent,
    ClinicDetail,
    ImageLogoBox,
    PatientInfo,
    TitlePrescription,
    Footer,
    DetailPrescription,
  },
  computed: {
    today() {
      return moment().format("DD-MM-YYYY");
    },
    userName() {
      let user = this.$store.getters.userData;
      return user.fullName;
    },
    dilation() {
      return this.ou.dlc != "" ? `(Sau liệt DT - ${this.ou.dlc})` : "";
    },
    printTypeNewGlass() {
      return this.ou.newGlass.newTypeGlass == ""
        ? ""
        : `(${this.ou.newGlass.newTypeGlass})`;
    },
    displayNote() {
      if (this.ou.newGlass.note == "") {
        return [];
      }
      return this.ou.newGlass.note.split(", ");
    },
  },
  data: () => ({
    isVnLanguage: i18n.locale == "vn",

    personalInfo: {},
    clinic: {},

    od: {
      subjective: {
        rx: "",
        refractiveError: "",
        va: "",
        add: "",
        nva: "",
        addToNVA: "",
      },
      objective: {
        rx: "",
        dlc: "",
      },
      newGlass: {
        rx: "",
        va: "",
        add: "",
        nva: "",
        addToNVA: "",
        note: "",
        newTypeGlass: "",
        bcva: "",
      },
      tonometer: {
        iop: "",
      },
      currentGlass: {
        iol: false,
        progressiveGlass: {
          rx: "",
          va: "",
        },
        nearGlass: {
          rx: "",
        },
        distantGlass: {
          rx: "",
          va: "",
        },
      },
      visualAcuity: {
        sc: "",
        cc: "",
      },
    },
    os: {
      subjective: {
        rx: "",
        refractiveError: "",
        va: "",
        add: "",
        nva: "",
        addToNVA: "",
      },
      objective: {
        rx: "",
        dlc: "",
      },
      newGlass: {
        rx: "",
        va: "",
        add: "",
        nva: "",
        addToNVA: "",
        note: "",
        newTypeGlass: "",
        bcva: "",
      },
      tonometer: {
        iop: "",
      },
      currentGlass: {
        progressiveGlass: {
          rx: "",
          va: "",
        },
        nearGlass: {
          rx: "",
        },
        distantGlass: {
          rx: "",
          va: "",
        },
      },
      visualAcuity: {
        sc: "",
        cc: "",
      },
    },
    ou: {
      pd: "",
      subjective: {
        bcva: "",
      },
      objective: {
        rx: "",
        dlc: "",
      },
      newGlass: {
        rx: "",
        va: "",
        add: "",
        nva: "",
        addToNVA: "",
        note: "",
        newTypeGlass: "",
        bcva: "",
      },
      visualAcuity: {
        sc: "",
        cc: "",
      },
    },
    firstCGElement: {},
    totalRowSpanCG: 0,
    listCGElement: [],
  }),
  created() {
    this.print();
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("DD-MM-YYYY");
    },
    async print() {
      await awaitAll([
        this.getResultGlassPrescription(),
        this.renderPatientDetail(),
        this.renderClinicDetail(),
      ]);
      window.print();
    },
    async getResultGlassPrescription() {
      var episodeRecordID = this.$route.params.episodeRecordID;
      var resultGlassPrescription =
        await OptometryConclusionService.getPrintPrescription(episodeRecordID);
      if (resultGlassPrescription.error) {
        this.$toast.error(resultGlassPrescription.message);
        return;
      }

      this.od = resultGlassPrescription.od;
      this.os = resultGlassPrescription.os;
      this.ou = resultGlassPrescription.ou;

      this.rowSpanCurrentGlass();

      if (this.listCGElement.length > 0) {
        this.firstCGElement = this.listCGElement[0];
        // delete first element
        this.listCGElement.shift();
      }

      if (this.print) {
        setTimeout(() => {
          window.print();
          // turn off that function
          setTimeout(() => {
            window.close();
          }, 500);
        }, 500);
      } else {
        return;
      }
    },

    emptyValueObject(obj) {
      for (var key in obj) {
        if (obj[key] !== null && obj[key] != "") return true;
      }
      return false;
    },

    checkCurrentGlass(obj) {
      for (var key in obj) {
        if (this.emptyValueObject(obj[key])) return true;
      }
      return false;
    },

    rowSpanCurrentGlass() {
      for (var key in this.od.currentGlass) {
        let nameProp = key;
        if (typeof this.od.currentGlass[key] == "object") {
          if (
            this.emptyValueObject(this.od.currentGlass[key]) ||
            this.emptyValueObject(this.os.currentGlass[key])
          ) {
            this.totalRowSpanCG++;
            let item = {
              name: nameProp,
              od: this.od.currentGlass[key],
              os: this.os.currentGlass[key],
            };
            this.listCGElement.push(item);
          }
        }
      }
    },
    nameItemCG(string) {
      switch (string) {
        case "progressiveGlass":
          return "Đa tròng";
        case "nearGlass":
          return "Gần";
        case "distantGlass":
          return "Xa";
        default:
          return "";
      }
    },

    printStatusGlass(string) {
      switch (string) {
        case "Old glass":
          return "Kính cũ";
        case "No glass":
          return "Không kính";
        default:
          return "";
      }
    },

    async renderPatientDetail() {
      var patientID = this.$route.params.patientID;
      var result = await PatientService.getPatientDetail(patientID);
      if (result.error) {
        console.log("Cannot get patient info. Please try again later!");
        return;
      }
      const { personalInfo } = result;
      this.personalInfo = personalInfo;
      this.personalInfo.genderTypeName = this.personalInfo.genderType - 1;
    },
    async renderClinicDetail() {
      console.log(this.$store.getters.userData.clinicID);
      let clinicID = this.$store.getters.userData.clinicID;
      var result = await BrandService.getBrandClinicByID(clinicID);
      if (result.error) {
        console.log("Cannot get clinic info. Please try again later!");
        return;
      }
      console.log(result);
      this.clinic = result;
    },
  },
};
</script>

<style>
</style>