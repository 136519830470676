import HttpClient from '../plugins/httpClient'
import AppConstant from '../plugins/constant'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Pas)

export default {
    searchPatients: async (keySearch = "", idSearch = "", aidSearch = "", phoneSearch = "", dobSearch = "", addressSearch = "", page = 1, limit = 10) => {
        var query = {
            keySearch,
            idSearch,
            phoneSearch,
            dobSearch,
            aidSearch,
            addressSearch,
        }
        var headers = {
            page,
            limit: limit || AppConstant.DefaultPaginationLimit,
        }
        return httpClient.get(`Patient/Search`, query, headers);
    },
    createPatient: async (data) => {
        return httpClient.post(`Patient/Create`, {}, data);
    },
    updatePatient: async (patientID, data) => {
        var query = {
            patientID
        }
        return httpClient.put(`Patient/Update/{patientID}`, query, data);
    },
    deletePatient: async (patientID) => {
        var query = {
            patientID
        }
        return httpClient.put(`Patient/Delete/{patientID}`, query);
    },
    getPatientDetail(patientID) {
        var query = {
            patientID
        }
        return httpClient.get(`/Patient/Get/{patientID}`, query);
    },
    updatePatientBookingInfoByID(patientID, data) {
        var query = {
            patientID
        }
        return httpClient.put(`/Patient/Update/{patientID}`, query, data);
    },
    getOccupationOption(keySearch, page, limit) {
        var query = {
            keySearch
        }
        var headers = {
            page,
            limit: limit || AppConstant.DefaultPaginationLimit
        }
        return httpClient.get(`/Patient/Option/Occupation/Search`, query, headers);
    },
    getNationalityOption(keySearch, page, limit) {
        var query = {
            keySearch
        }
        var headers = {
            page,
            limit: limit || AppConstant.DefaultPaginationLimit
        }
        return httpClient.get(`/Patient/Option/Nationality/Search`, query, headers);
    },
    getEthnicityOption(keySearch, page, limit) {
        var query = {
            keySearch
        }
        var headers = {
            page,
            limit: limit || AppConstant.DefaultPaginationLimit
        }
        return httpClient.get(`/Patient/Option/Ethnicity/Search`, query, headers);
    },
    searchLastVisits(patientID, keySearch = "", page = 1, limit = AppConstant.DefaultPaginationLimit) {
        var query = {
            patientID,
            keySearch,
        }
        var header = {
            page,
            limit,
        }
        return httpClient.get(`/Admission/LastVisits/{patientID}`, query, header);
    },
    getChronicDiseasesOption(keySearch, page, limit) {
        var query = { keySearch };
        var headers = {
            page,
            limit: limit || AppConstant.DefaultPaginationLimit
        };
        return httpClient.get(`/ChronicDisease/Search`, query, headers);
    }
}