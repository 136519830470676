import HttpClient from '../plugins/httpClient'
//import AppConstant from '../plugins/constant'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Emr);

const prefix = '/OptometryConclusion'
export default {
  getPrintPrescription: (episodeRecordID) => {
    var query = {
      episodeRecordID
    }
    return httpClient.get(`${prefix}/GlassPrescription`, query);
  },
  
  getElementConclusion: (episodeRecordID, listElement) => {
    var query = {
      episodeRecordID,
      listElement
    }
    return httpClient.get(`${prefix}/GetElementConclusion`, query)
  },

  getPrintClsPrescription: (episodeRecordID, typeCls) => {
    var query = {
      episodeRecordID
    }
    return httpClient.get(`${prefix}/ClsPrescription/${typeCls}`, query)
  } 
}